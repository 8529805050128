<template>
  <v-dialog
    max-width="800px"
    :value="true"
  >
    <v-card>
      <v-row>
        <v-col cols="6">
          <ItemGrid :items="items" />
        </v-col>
        <v-col cols="6">
          <Order :order="order" />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

import ItemGrid from '../item/ItemGrid'
import Order from '../order/Order'

export default {
    name: 'SaleDialog',
    data() {
        return {
            order: {
                lineItems: []
            }
        }
    },
    components: {
        ItemGrid,
        Order
    },
    computed: {
        items() {
            return this.$store.state.items.filter(item => item.type === 'event')
        }
    }
}
</script>
